import * as React from "react"
import Helmet from "react-helmet";
import Layout from '../../components/Layout'
import {styled, useTheme} from '@mui/material/styles'
import Box from "@mui/material/Box";
import ProjectHeader, {CoverContent, MyVideo} from "../../components/Common/ProjectHeader";
import {browserTitle, seoDescription, seoTitle, keywords} from "../../data/products/edge";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid/Grid";
import {BackBox, Box4Title, CennterBox} from "../../components/Common/Base";
import Stack from "@mui/material/Stack";
import { SubTitleEnBox, SubTitleWhiteBox, ProductContact } from "../../components/Common/Base";
import config from "../../config";
import VideoJs from "../../components/Video";
import Typography from "@mui/material/Typography/Typography";
import Paper from "@mui/material/Paper/Paper";


const City3d = (props) => {
  const { location } = props;

  const eleShow = React.useRef();

  const handleNextClick = () => {
    eleShow.current && eleShow.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    })
  };

  return (
    <Layout location={location}>
      <Helmet>
        <meta name="title" content={seoTitle} />
        <meta name="description" content={seoDescription} />
        <meta name="keywords" content={keywords} />
        <title>{browserTitle}</title>
      </Helmet>
      <ProjectHeader
        // backVideo={require('../../images/products/edge/01.mp4').default}
        backImg={require('../../images/products/edge/edge.png').default}
        backVideo={'products/edge/01.m3u8'}
        title={'计算机视觉边缘计算'}
        titleEn={'Computer Vision edge computing'}
        // titleEn2={'Hyper Resolution Wind Simulation System'}
        // subTitle={'天气模拟软件'}
        gridShow
        handleNextClick={handleNextClick}
      />
      {/* box2 */}
      <BackBox
        ref={eleShow}
        sx={{
          backgroundImage: {
            xs: 'none',
            sm: `url(${require('../../images/products/edge/02.png').default})`
          },
          pt: {
            xs: 4,
            md: 8,
            lg: 16,
          },
          pb: {
            xs: 4,
            md: 8,
            lg: 16,
          },
        }}
      >
        <Container
          maxWidth="xl"
        >
          <Stack
            direction="column"
            spacing={0}
            sx={{

            }}
          >
            <Box4Title
              variant="h4"
              gutterBottom
              sx={{
                textAlign: {
                  xs: 'center',
                }
              }}
            >
              车辆识别
            </Box4Title>
            <Box4Title
              variant="h4"
              gutterBottom
              sx={{
                textAlign: {
                  xs: 'center',
                },
                pb: {
                  xs: 2,
                  md: 4,
                  lg: 6,
                }
              }}
            >
              Vehicle identification
            </Box4Title>
          </Stack>

          <Container
            maxWidth="md"
            sx={{
              p: 0,
              pb: {
                xs: 2,
                md: 4,
                lg: 6,
              }
            }}
          >
            <SubTitleWhiteBox
              variant="h5"
              gutterBottom
            >
              基于自研的车辆检测和跟踪算法，能够适配不同光照、天气、遮挡等复杂场景，能够快速准确的定位车辆，实现毫秒级的检测和高效实时跟踪。
            </SubTitleWhiteBox>
            <SubTitleEnBox
              gutterBottom
              variant="h6"
              sx={{
                // color: '#fff',
              }}
            >
              Based on the self-developed vehicle detection and tracking algorithm,
              it can adapt to different lighting, weather, occlusion and other complex scenes,
              and can quickly and accurately locate vehicles, realizing millisecond
              level detection and efficient real-time tracking.
            </SubTitleEnBox>
          </Container>
          <Grid
            container
            spacing={4}
          >
            <Grid
              item
              xs={4}
            >
              <img width="100%" alt="" src={require('../../images/products/edge/03.png').default} />
            </Grid>
            <Grid
              item
              xs={4}
            >
              <img width="100%" alt="" src={require('../../images/products/edge/04.png').default} />
            </Grid>
            <Grid
              item
              xs={4}
            >
              <img width="100%" alt="" src={require('../../images/products/edge/05.png').default} />
            </Grid>
          </Grid>
        </Container>
      </BackBox>
      {/* box3 */}
      <BackBox
        sx={{
          backgroundImage: {
            xs: 'none',
            sm: `url(${require('../../images/products/edge/06.png').default})`
          },
          pt: {
            xs: 4,
            md: 8,
            lg: 16,
          },
          pb: {
            xs: 4,
            md: 8,
            lg: 16,
          },
        }}
      >
        <Container
          maxWidth="xl"
        >
          <Stack
            direction="column"
            spacing={0}
            sx={{

            }}
          >
            <Box4Title
              variant="h4"
              gutterBottom
              sx={{
                textAlign: {
                  xs: 'center',
                }
              }}
            >
              红外识别
            </Box4Title>
            <Box4Title
              variant="h4"
              gutterBottom
              sx={{
                textAlign: {
                  xs: 'center',
                },
                pb: {
                  xs: 2,
                  md: 4,
                  lg: 6,
                }
              }}
            >
              Infrared recognition
            </Box4Title>
          </Stack>

          <Container
            maxWidth="md"
            sx={{
              p: 0,
              pb: {
                xs: 2,
                md: 4,
                lg: 6,
              }
            }}
          >
            <SubTitleWhiteBox
              variant="h5"
              gutterBottom
            >
              红外光识别技术是一种通过使用红外线来识别物体的方法。红外线是一种非可见光波，它具有特殊的吸收和反射特性，
              因此对于不同的物体具有不同的红外光谱响应。在红外光识别技术中，红外摄像机通过捕捉物体的红外反射信号并通过数字图像处理算法对图像进行分析，
              以识别物体的形状、大小、位置等信息。这些信息可用于进行人工智能识别，如人脸识别、物品识别。
            </SubTitleWhiteBox>
            <SubTitleEnBox
              gutterBottom
              variant="h6"
              sx={{
                // color: '#fff',
              }}
            >
              In infrared light recognition technology, an infrared camera captures the infrared reflection
              signal of an object and analyzes the image through a digital image processing algorithm to identify
              the shape, size, location and other information of the object. This information can be used to
              perform artificial intelligence recognition, such as face recognition and object recognition.
            </SubTitleEnBox>
          </Container>
          <Box>
            <img width="100%" alt="" src={require('../../images/products/edge/07.png').default} />
          </Box>

        </Container>
      </BackBox>
      {/* box4 */}
      <BackBox
        sx={{
          backgroundImage: {
            xs: 'none',
            sm: `url(${require('../../images/products/edge/08.png').default})`
          },
          pt: {
            xs: 4,
            md: 8,
            lg: 16,
          },
          pb: {
            xs: 4,
            md: 8,
            lg: 16,
          },
        }}
      >
        <Container
          maxWidth="xl"
        >
          <Stack
            direction="column"
            spacing={0}
            sx={{

            }}
          >
            <Box4Title
              variant="h4"
              gutterBottom
              sx={{
                textAlign: {
                  xs: 'center',
                }
              }}
            >
              生物及物体识别
            </Box4Title>
            <Box4Title
              variant="h4"
              gutterBottom
              sx={{
                textAlign: {
                  xs: 'center',
                },
                pb: {
                  xs: 2,
                  md: 4,
                  lg: 6,
                }
              }}
            >
              Biological and object recognition
            </Box4Title>
          </Stack>

          <Container
            maxWidth="md"
            sx={{
              p: 0,
              pb: {
                xs: 2,
                md: 4,
                lg: 6,
              }
            }}
          >
            <SubTitleWhiteBox
              variant="h5"
              gutterBottom
            >
              我们的方法可以涵盖超过 2980 种不同的物体类别和场景，每种场景中有20到90种不同的变化。
              我们的算法可以捕捉图像中的复杂多样的场景，并且提供了丰富的注释数据，包括物体的位置、大小和语义标签。
            </SubTitleWhiteBox>
            <SubTitleEnBox
              gutterBottom
              variant="h6"
              sx={{
                // color: '#fff',
              }}
            >
              Our approach can cover over 80 different object classes and scenes, with 20 to 90 different
              variations in each scene. Our algorithm captures complex and diverse scenes in images and
              provides richly annotated data, including object positions, sizes, and semantic labels.
            </SubTitleEnBox>
          </Container>
          <Stack
            direction={{
              xs: 'column',
              md: 'row',
            }}
            spacing={2}
            justifyContent="space-between">
            <Box
              sx={{
                width:{
                  xs: '100%',
                  md: '40%',
                }}
              }
            >
              <img width="100%" alt="" src={require('../../images/products/edge/09.png').default} />
            </Box>

            <Box
              sx={{
                width:{
                  xs: '100%',
                  md: '55%',
                }}}
            >
              <img width="100%" alt="" src={require('../../images/products/edge/10.png').default} />
            </Box>
          </Stack>
        </Container>
      </BackBox>
      {/* box4 */}
      <BackBox
        sx={{
          backgroundImage: {
            xs: 'none',
            // sm: `url(${require('../../images/products/edge/08.png').default})`
          },
          pt: {
            xs: 4,
            md: 8,
            lg: 16,
          },
          pb: {
            xs: 4,
            md: 8,
            lg: 16,
          },
        }}
      >
        <Container
          maxWidth="xl"
        >
          <Stack
            direction="column"
            spacing={0}
            sx={{

            }}
          >
            <Box4Title
              variant="h4"
              gutterBottom
              sx={{
                textAlign: {
                  xs: 'center',
                }
              }}
            >
              行人跟踪识别
            </Box4Title>
            <Box4Title
              variant="h4"
              gutterBottom
              sx={{
                textAlign: {
                  xs: 'center',
                },
                pb: {
                  xs: 2,
                  md: 4,
                  lg: 6,
                }
              }}
            >
              Pedestrian tracking and recognition
            </Box4Title>
          </Stack>

          <Container
            maxWidth="md"
            sx={{
              p: 0,
              pb: {
                xs: 2,
                md: 4,
                lg: 6,
              }
            }}
          >
            <SubTitleWhiteBox
              variant="h5"
              gutterBottom
            >
              行人重识别(ReID)技术基于深度学习技术和海量数据实现的行人综合特征识别算法，能够快速的提取到行人特征向量，
              实现20种行人属性识别，如：性别、帽子、上下身服装颜色、上下身服装类型、拿包、年龄、口罩、眼镜、头盔、朝向等。

            </SubTitleWhiteBox>
            <SubTitleEnBox
              gutterBottom
              variant="h6"
              sx={{
                // color: '#fff',
              }}
            >
              Pedestrian Rerecognition (ReID) technology Based on deep learning technology and mass data to achieve
              pedestrian comprehensive feature recognition algorithm, can quickly extract pedestrian feature vector,
              achieve 20 pedestrian attributes recognition, such as: gender, hat, upper and lower body clothing color,
              upper and lower body clothing type, carrying bag, age, mask, glasses, helmet, orientation, etc.
            </SubTitleEnBox>
          </Container>

          <Grid
            container
            spacing={4}
          >
            <Grid
              item
              xs={12}
              md={4}
            >
              <img width="100%" alt="" src={require('../../images/products/edge/11.png').default} />
            </Grid>
            <Grid
              item
              xs={12}
              md={8}
              container
              spacing={4}
            >
              <Grid
                item
                xs={3}
              >
                <img width="100%" alt="" src={require('../../images/products/edge/12.png').default} />
              </Grid>
              <Grid
                item
                xs={3}
              >
                <img width="100%" alt="" src={require('../../images/products/edge/13.png').default} />
              </Grid>
              <Grid
                item
                xs={3}
              >
                <img width="100%" alt="" src={require('../../images/products/edge/14.png').default} />
              </Grid>
              <Grid
                item
                xs={3}
              >
                <img width="100%" alt="" src={require('../../images/products/edge/15.png').default} />
              </Grid>
            </Grid>
          </Grid>

        </Container>
      </BackBox>
      {/* box5 */}
      <BackBox
        sx={{
          backgroundImage: {
            xs: 'none',
            sm: `url(${require('../../images/products/edge/16.png').default})`
          },
          pt: {
            xs: 4,
            md: 8,
            lg: 16,
          },
          pb: {
            xs: 4,
            md: 8,
            lg: 16,
          },
        }}
      >
        <Container
          maxWidth="xl"
        >
          <Stack
            direction="column"
            spacing={0}
            sx={{

            }}
          >
            <Box4Title
              variant="h4"
              gutterBottom
              sx={{
                textAlign: {
                  xs: 'center',
                }
              }}
            >
              人脸, 年龄, 种族识别
            </Box4Title>
            <Box4Title
              variant="h4"
              gutterBottom
              sx={{
                textAlign: {
                  xs: 'center',
                },
                pb: {
                  xs: 2,
                  md: 4,
                  lg: 6,
                }
              }}
            >
              Face, age, race recognition
            </Box4Title>
          </Stack>
          <Grid
            container
            spacing={4}
          >
            <Grid
              item
              xs={12}
              md={12}
              lg={4}
            >

              <Container
                maxWidth="md"
                sx={{
                  p: 0,
                  pb: {
                    xs: 2,
                    md: 4,
                    lg: 6,
                  }
                }}
              >
                <SubTitleWhiteBox
                  variant="h5"
                  gutterBottom
                >
                  人脸、年龄和种族识别技术可以用于许多不同的应用。其中一些应用包括安全和认证、市场营销、人口统计和医疗保健。
                </SubTitleWhiteBox>
                <SubTitleEnBox
                  gutterBottom
                  variant="h6"
                  sx={{
                    // color: '#fff',
                  }}
                >
                  Pedestrian Rerecognition (ReID) technology Based on deep learning technology and
                  mass data to achieve pedestrian comprehensive feature recognition algorithm, can
                  quickly extract pedestrian feature vector, achieve 20 pedestrian attributes recognition,
                  such as: gender, hat, upper and lower body clothing color, upper and lower body clothing type,
                  carrying bag, age, mask, glasses, helmet, orientation, etc.
                </SubTitleEnBox>
              </Container>
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
              lg={8}
            >
              <img width="100%" alt="" src={require('../../images/products/edge/17.png').default} />
            </Grid>
          </Grid>
        </Container>
      </BackBox>
      {/* box5 */}
      <Container
        maxWidth="xl"
        sx={{
          pt: {
            xs: 4,
            md: 8,
            lg: 16,
          },
          pb: {
            xs: 4,
            md: 8,
            lg: 16,
          },
        }}
      >
        <Grid
          container
          spacing={4}
          alignItems="stretch"
        >
          <Grid
            item
            xs={12}
            md={6}
            lg={3}
          >
            <FullPaper
              elevation={6}
            >
              <SubTitleWhiteBox
                variant="h5"
                gutterBottom
                sx={{
                  color: '#1BAFBF',
                  mb: {
                    xs: 2,
                    md: 3,
                    lg: 4
                  },
                }}
              >
                市场营销方面
              </SubTitleWhiteBox>
              <SubTitleWhiteBox
                variant="h5"
                gutterBottom
              >
                可以用于定位特定年龄和种族群体的广告，以及跟踪消费者行为和兴趣，从而更好地了解其需求和购买决策。
              </SubTitleWhiteBox>
              <SubTitleEnBox
                gutterBottom
                variant="h6"
                sx={{
                  // color: '#fff',
                }}
              >
                It can be used to locate advertisements of specific age and ethnic groups, and track
                consumer behavior and interests, so as to better understand their needs and purchase decisions.
              </SubTitleEnBox>
            </FullPaper>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            lg={3}
          >
            <FullPaper
              elevation={6}
            >
              <SubTitleWhiteBox
                variant="h5"
                gutterBottom
                sx={{
                  color: '#1BAFBF',
                  mb: {
                    xs: 2,
                    md: 3,
                    lg: 4
                  },
                }}
              >
                安全和认证方面
              </SubTitleWhiteBox>
              <SubTitleWhiteBox
                variant="h5"
                gutterBottom
              >
                人脸识别技术可以用于身份验证和访问控制。
              </SubTitleWhiteBox>
              <SubTitleEnBox
                gutterBottom
                variant="h6"
                sx={{
                  // color: '#fff',
                }}
              >
                例如在银行、政府机构和公司的入口处。
              </SubTitleEnBox>
              <SubTitleWhiteBox
                variant="h5"
                gutterBottom
              >
                年龄识别技术可以用于限制某些内容的访问。
              </SubTitleWhiteBox>
              <SubTitleEnBox
                gutterBottom
                variant="h6"
                sx={{
                  // color: '#fff',
                }}
              >
                例如在儿童游戏或社交媒体应用中，以保护未成年人免受不适当的内容影响。
              </SubTitleEnBox>
              <SubTitleWhiteBox
                variant="h5"
                gutterBottom
              >
                种族识别技术也可以用于安全和认证。
              </SubTitleWhiteBox>
              <SubTitleEnBox
                gutterBottom
                variant="h6"
                sx={{
                  // color: '#fff',
                }}
              >
                例如在海关和边境检查站，以及犯罪调查中。
                <br />
                Face recognition technology can be used for authentication and access control.
                Age recognition technology can be used to restrict access to certain content.
                Race identification technology can also be used for security and authentication.
              </SubTitleEnBox>
            </FullPaper>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            lg={3}
          >
            <FullPaper
              elevation={6}
            >
              <SubTitleWhiteBox
                variant="h5"
                gutterBottom
                sx={{
                  color: '#1BAFBF',
                  mb: {
                    xs: 2,
                    md: 3,
                    lg: 4
                  },
                }}
              >
                人口统计方面
              </SubTitleWhiteBox>
              <SubTitleWhiteBox
                variant="h5"
                gutterBottom
              >
                可以用于收集人口数据和统计信息，例如在人口普查和社会研究中。
                这些数据可以帮助政府和企业了解不同年龄和种族群体的需求和趋势，从而更好地制定政策和服务。
              </SubTitleWhiteBox>
              <SubTitleEnBox
                gutterBottom
                variant="h6"
                sx={{
                  // color: '#fff',
                }}
              >
                It can be used to collect demographic data and statistical information,
                such as in population census and social research. These data can help governments
                and enterprises understand the needs and trends of different age and ethnic groups,
                so as to better formulate policies and services.
              </SubTitleEnBox>
            </FullPaper>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            lg={3}
          >
            <FullPaper
              elevation={6}
            >
              <SubTitleWhiteBox
                variant="h5"
                gutterBottom
                sx={{
                  color: '#1BAFBF',
                  mb: {
                    xs: 2,
                    md: 3,
                    lg: 4
                  },
                }}
              >
                医疗保健方面
              </SubTitleWhiteBox>
              <SubTitleWhiteBox
                variant="h5"
                gutterBottom
              >
                用于识别患者的年龄和种族，从而更好地定制治疗方案和提供个性化的医疗服务。
              </SubTitleWhiteBox>
              <SubTitleEnBox
                gutterBottom
                variant="h6"
                sx={{
                  // color: '#fff',
                }}
              >
                It is used to identify the age and ethnicity of patients to
                better tailor treatment plans and provide personalized medical care.
              </SubTitleEnBox>
            </FullPaper>
          </Grid>
        </Grid>
      </Container>
      {/* box6 */}
      <Container
        maxWidth="xl"
        sx={{
          pt: {
            xs: 4,
            md: 8,
            lg: 16,
          },
          pb: {
            xs: 4,
            md: 8,
            lg: 16,
          },
        }}
      >
        <Stack
          direction="column"
          spacing={0}
          sx={{

          }}
        >
          <Box4Title
            variant="h4"
            gutterBottom
            sx={{
              textAlign: {
                xs: 'center',
              }
            }}
          >
            火焰识别
          </Box4Title>
          <Box4Title
            variant="h4"
            gutterBottom
            sx={{
              textAlign: {
                xs: 'center',
              },
              pb: {
                xs: 2,
                md: 4,
                lg: 6,
              }
            }}
          >
            Flame identification
          </Box4Title>
        </Stack>
        <Grid
          container
          spacing={4}
          sx={{mb: 2}}
        >
          <Grid
            item
            xs={12}
            sm={4}
          >
            <img width="100%" alt="" src={require('../../images/products/edge/fire/1.png').default} />
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
          >
            <img width="100%" alt="" src={require('../../images/products/edge/fire/2.png').default} />
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
          >
            <img width="100%" alt="" src={require('../../images/products/edge/fire/3.png').default} />
          </Grid>
        </Grid>
        <Stack
          justifyContent="space-between"
          direction={{
            xs: "column",
            sm: "row"
          }}
        >
          <Box
            sx={{
              width: {
                xs: "100%",
                sm: '38%'
              },
            }}
          >
            <img width="100%" alt="" src={require('../../images/products/edge/fire/4.png').default} />
          </Box>
          <Box
            sx={{
              width: {
                xs: "100%",
                sm: '18.6%'
              },
            }}
          >
            <img width="100%" alt="" src={require('../../images/products/edge/fire/5.png').default} />
          </Box>
          <Box
            sx={{
              width: {
                xs: "100%",
                sm: '36.6%'
              },
            }}
          >
            <img width="100%" alt="" src={require('../../images/products/edge/fire/6.png').default} />
          </Box>

        </Stack>
      </Container>
      {/* box7 */}
      <BackBox
        sx={{
          backgroundImage: {
            xs: 'none',
            sm: `url(${require('../../images/products/edge/18.png').default})`
          },
          pt: {
            xs: 4,
            md: 8,
            lg: 16,
          },
          pb: {
            xs: 4,
            md: 8,
            lg: 16,
          },
        }}
      >
        <Container
          maxWidth="xl"
        >
          <Stack
            direction="column"
            spacing={0}
            sx={{

            }}
          >
            <Box4Title
              variant="h4"
              gutterBottom
              sx={{
                textAlign: {
                  xs: 'center',
                }
              }}
            >
              多摄像头识别
            </Box4Title>
            <Box4Title
              variant="h4"
              gutterBottom
              sx={{
                textAlign: {
                  xs: 'center',
                },
                pb: {
                  xs: 2,
                  md: 4,
                  lg: 6,
                }
              }}
            >
              Multi-camera identification
            </Box4Title>
          </Stack>

          <Container
            maxWidth="md"
            sx={{
              p: 0,
              pb: {
                xs: 2,
                md: 4,
                lg: 6,
              }
            }}
          >
            <SubTitleWhiteBox
              variant="h5"
              gutterBottom
            >
              利用人工智能（AI）的神经网络技术分析动态视频监控图像，智能识别跑道上的物体，产生提醒报警，
              包括除飞机外的其他物体（人、车、活体动物），并即时响应安全服务。
            </SubTitleWhiteBox>
            <SubTitleEnBox
              gutterBottom
              variant="h6"
              sx={{
                // color: '#fff',
              }}
            >
              Using artificial intelligence (AI) neural network technology to analyze dynamic video surveillance images,
              intelligently identify objects on the runway, generate alerts and alarms, including other objects besides
              aircraft (people, cars, live animals), and immediately respond to security services.
            </SubTitleEnBox>
          </Container>
          <Container
            maxWidth="md"
            sx={{
              p: 0,
              pb: {
                xs: 2,
                md: 4,
                lg: 6,
              }
            }}
          >
            <Stack direction="row" justifyContent="space-between">
              <Box
                sx={{
                  width: {
                    xs: '100%',
                    sm: '65.5%'
                  }
                }}
              >
                <img width="100%" alt="" src={require('../../images/products/edge/19.png').default} />
              </Box>
              <Box
                sx={{
                  width: '32.4%',
                  display: {
                    xs: 'none',
                    sm: 'block',
                  }
                }}
              >
                <img width="100%" alt="" src={require('../../images/products/edge/20.png').default} />
              </Box>
            </Stack>
          </Container>

          <Container
            maxWidth="md"
            sx={{
              p: 0,
              pb: {
                xs: 2,
                md: 4,
                lg: 6,
              }
            }}
          >
            <SubTitleWhiteBox
              variant="h5"
              gutterBottom
            >
              通过在跑道附近部署多个摄像头来检测外来入侵物体，并通过分析不同摄像头的拍摄结果来降低误判率。
            </SubTitleWhiteBox>
            <SubTitleEnBox
              gutterBottom
              variant="h6"
              sx={{
                // color: '#fff',
              }}
            >
              Multiple cameras are deployed near the runway to detect foreign intrusion objects,
              and the misjudgment rate is reduced by analyzing the results of different cameras.
            </SubTitleEnBox>
          </Container>
          <Container
            maxWidth="md"
            sx={{
              display: {
                xs: 'block',
                md: 'none',
              },
              p: 0,
              pb: {
                xs: 2,
                md: 4,
                lg: 6,
              }
            }}
          >
            <img width="100%" alt="" src={require('../../images/products/edge/20.png').default} />
          </Container>

        </Container>
      </BackBox>
      {/* box8 */}
      <BackBox
        sx={{
          backgroundImage: {
            xs: 'none',
            sm: `url(${require('../../images/products/edge/21.png').default})`
          },
          pt: {
            xs: 4,
            md: 8,
            lg: 16,
          },
          pb: {
            xs: 4,
            md: 8,
            lg: 16,
          },
        }}
      >
        <Container
          maxWidth="xl"
        >
          <Stack
            direction="column"
            spacing={0}
            sx={{

            }}
          >
            <Box4Title
              variant="h4"
              gutterBottom
              sx={{
                textAlign: {
                  xs: 'center',
                }
              }}
            >
              跑道入侵快速响应
            </Box4Title>
            <Box4Title
              variant="h4"
              gutterBottom
              sx={{
                textAlign: {
                  xs: 'center',
                },
                pb: {
                  xs: 2,
                  md: 4,
                  lg: 6,
                }
              }}
            >
              Rapid response to runway intrusion
            </Box4Title>
          </Stack>

          <Grid
            container
            spacing={4}
            sx={{mb: 2}}
          >
            <Grid
              item
              xs={12}
              sm={4}
            >
              <img width="100%" alt="" src={require('../../images/products/edge/rq/1.png').default} />
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
            >
              <img width="100%" alt="" src={require('../../images/products/edge/rq/2.png').default} />
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
            >
              <img width="100%" alt="" src={require('../../images/products/edge/rq/3.png').default} />
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
            >
              <img width="100%" alt="" src={require('../../images/products/edge/rq/4.png').default} />
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
            >
              <img width="100%" alt="" src={require('../../images/products/edge/rq/5.png').default} />
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
            >
              <img width="100%" alt="" src={require('../../images/products/edge/rq/6.png').default} />
            </Grid>
          </Grid>

        </Container>
      </BackBox>
      {/* box9 */}
      <ProductContact />
    </Layout>
  )
}


export default City3d;

const FullPaper = styled(Paper)(() => ({
  width: '100%',
  height: '100%',
  padding: '15px',
}));
